import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, EventEmitter, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { IChildProfile } from '@interfaces/child-profile.interface';
import { IChildrenPayload } from '@interfaces/children-payload.interface';
import { Almuerzo } from '@models/almuerzo';
import { Child } from '@models/child';
import { Ruta } from '@models/ruta';
import { ChildPayload } from '@payloads/child.payload';
import { IAlmuerzoResponse } from '@server-responses/almuerzo-response.interface';
import { IChildrenResponse } from '@server-responses/children-response.interface';
import { IRutasResponse } from '@server-responses/rutas-response.interface';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChildrenService {
  @Output() propagar: EventEmitter<any> = new EventEmitter();
  constructor(private httpClient: HttpClient) {}

  private childUpdatedSource = new BehaviorSubject<IChildProfile>(null);
  private childrenUpdatedSource = new BehaviorSubject<IChildProfile[]>(null);

  public childUpdated = this.childUpdatedSource.asObservable();
  public childrenUpdated = this.childrenUpdatedSource.asObservable();

  childUpdate(child: IChildProfile): void {
    this.childUpdatedSource.next(child);
  }

  childrenUpdate(children: IChildProfile[]): void {
    this.childrenUpdatedSource.next(children);
  }

  checkForExistingLead(childrenId: string): any {
    return this.httpClient.get(
      `${environment.API}/children/${childrenId}/existed_lead`
    );
  }

  downloadPdf(): any {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    const fileId = '1619474102243.pdf';
    return this.httpClient.get(`${environment.API}/children/${fileId}`, {
      headers,
      responseType: 'blob' as 'json',
    });
  }

  /**
   * @update Adrian Avendano Concha
   * @date 17/01/2022
   */
  getChildren(): any {
    return this.httpClient.get(`${environment.API}/children`).pipe(
      map((response: IChildrenResponse) => {
        return response.children.map((child: IChildrenPayload) => {
          return new Child(child);
        });
      })
    );
  }

  getChildrenScreening(childId: string): any {
    return this.httpClient.get(
      `${environment.API}/children/${childId}/screening`
    );
  }

  /**
   * @author Adrian Avendano Concha
   * @date 03/03/2022
   */
  getChildrenScreeningLambda(childId: string): any {
    return this.httpClient.get(
      `${environment.API3}/AolChildScreening?child_id=${childId}`
    );
  }
  getPaymentNow(childId: string): any {
    return this.httpClient.get(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/webhookEpayco?child_id=${childId}`
    );
  }
   /**
   * @author Alex Felipe Carvajal
   * @date 03/05/2023
   */
  getTransfer(childId: string): any {
    return this.httpClient.get(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/validateTransferStudents?child_id=${childId}`
    );
  }

  /**
   * @author Adrian Avendano Concha
   * @date 10/06/2022
   */
  getChildPaymentStatus(childId: string): any {
    return this.httpClient.get(
      `${environment.API_ENDPOINTS}/aolservices/statuspayment?child_id=${childId}`
    );
  }

  getFacturationStatus(userId: string): any {
    return this.httpClient.get(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/inovicingInformation?user_id=${userId}`
    );
  }

  saveScreening(childId: string, payload): any {
    return this.httpClient.post(
      `${environment.API}/children/${childId}/screening`,
      payload
    );
  }

  updateChild(payload: ChildPayload, id: string): any {
    return this.httpClient.put(
      `${environment.API}/children/${id}`,
      payload.toUpdateJSON()
    );
  }

  updateChild2(child_id: string, payload: any, accion: string): any {
    return this.httpClient.post(
      ` https://4qhccy6pqi.execute-api.us-east-1.amazonaws.com/otherPersonalInfoChildTest?accion=${accion}&childId=${child_id}`,
      payload
    );
  }

  getInfoChild2(child_id: string): any {
    return this.httpClient.get(
      ` https://4qhccy6pqi.execute-api.us-east-1.amazonaws.com/otherPersonalInfoChildTest?childId=` +
        child_id
    );
  }

  getInfoStepsEnrollment(): any {
    return this.httpClient.get(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/enrollmentProcessesFlow`
    );
  }

  processStepEnrollmentConfirmated(payload: any): any {
    return this.httpClient.post(
      `https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/enrollmentProcessesFlow`,
      payload
    );
  }

  updateChildDocuments(childId: string, payload: any): any {
    return this.httpClient.put(
      `${environment.API}/child-documents/${childId}`,
      payload
    );
  }

  uploadFile(payload: ChildPayload): any {
    return this.httpClient.post(
      `${environment.API}/children/upload-file`,
      payload.toFormData()
    );
  }

  scholarService(childId: string, payload: ChildPayload): any {
    return this.httpClient.post(
      `${environment.API}/children/${childId}/scholar-service/`,
      payload.updateAcademicInfo()
    );
  }

  verifyScreening(childId: any, screening_type: any): any {
    return this.httpClient.post(
      `${environment.API}/children/${childId}/verify-screening`,
      {
        screening_type,
      }
    );
  }

  /**
   * Validar si ya existe el documento del estudiante.
   * @author Adrian Avendano Concha
   * @date 11/01/2022
   */
  verifyDocument(child_id: string, doc: string, docType: string): any {
    const data = {
      childDocs: [
        {
          docType,
          docNumber: doc,
          childId: child_id,
        },
      ],
    };
    return this.httpClient.post(`${environment.API3}/validateChildId`, data);
  }

  /**
   * Guardar la opcion que selecciono el usuario respecto a la autorizacion de imagen.
   * @author Adrian Avendano Concha
   * @date 12/01/2022
   */
  updateImageAuth(
    child_id: string,
    user_id: string,
    auth: boolean,
    type: any,
  ): any {
    const data = {
      childId: child_id,
      userId: user_id,
      auth,
      type,
    };
    return this.httpClient.post(`${environment.API2}/imageAuthTest`, data);
  }

  /**
   * @autor Adrian Avendano Concha
   * @date 11/02/2022
   */
  getStatusContract(childId: string, serviceType: string): any {
    const data = {
      childId,
      service_type: serviceType,
    };
    return this.httpClient.post(
      `${environment.API3}/testAolIsContractSigned`,
      data
    );
  }

  /**
   * @autor Adrian Avendano Concha
   * @date 14/02/2022
   */
  getAlmuerzoAmount(childId: string): any {
    let url = `${environment.API3}/AolShowAlimentacionChild`;
    url += `?childId=${childId}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response.map((alumuerzo: IAlmuerzoResponse) => {
          return new Almuerzo(alumuerzo);
        });
      })
    );
  }

  /**
   * @autor Adrian Avendano Concha
   * @date 14/02/2022
   */
  getRoutesBySchool(childId: string): any {
    let url = `${environment.API3}/AolShowRutasPerSchool`;
    url += `?childId=${childId}`;
    return this.httpClient.get(url).pipe(
      map((response: IRutasResponse[]) => {
        return response.map((ruta: IRutasResponse) => {
          return new Ruta(ruta);
        });
      })
    );
  }

  /**
   * @autor Adrian Avendano Concha
   * @date 11/02/2022
   */
  uploadRutaSelection(childId: string, rutaId: string, rutaType: string): any {
    const data = {
      ruta_id: rutaId,
      tipo_ruta: rutaType,
    };
    let url = `${environment.API3}/AolSaveRutaSelection`;
    url += `?childId=${childId}`;
    return this.httpClient.post(url, data);
  }

  /**
   * @autor Alex Felipe Carvajal
   * @date 11/14/2022
   */
  saveFichaMedica(data: any): any {
    return this.httpClient.post(
      `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/student-ficha-medica`,
      data
    );
  }

  saveProccesOmnicanalidad(data: any): any {
    return this.httpClient.post(
      `https://0x7owuhf4g.execute-api.us-east-1.amazonaws.com/Prod/validateOmnicanalidad`,
      data
    );
  }

  getFichaMedica(childId: string): any {
    return this.httpClient.get(
      `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/student-ficha-medica?childId=${childId}`
    );
  }

  deleteFichaMedica(medicalRecordId: string): any {
    return this.httpClient.delete(
      `https://r7fe8z09si.execute-api.us-east-1.amazonaws.com/student-ficha-medica?medicalRecordId=${medicalRecordId}`
    );
  }

  /**
   * @autor Adrian Avendano Concha
   */
  getSchoolByChild(child_id: string): any {
    return this.httpClient.get(
      `${environment.API_ENDPOINTS}/aolservices/pipelines?child=${child_id}`
    );
  }

  /**
   * @autor Adrian Avendano Concha
   */
  generatePayment(form: any): any {
    const url = `${environment.API_ENDPOINTS}/aolservices/payments`;
    return this.httpClient.put(url, form).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /**
   * Validar si tiene pagos adicionales pendientes
   * @autor Adrian Avendano Concha
   * @date 04/11/2022
   */
  validateAdtionals(child_id: string, type: string): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/services?child_id=${child_id}&${type}=true`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  /**
   * Validar si el estudiante aplica para renovacion
   * @autor Adrian Avendano Concha
   * @date 04/11/2022
   */
  validateRenovation(child_id: string): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/renovation?child=${child_id}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  validateOmnicanalidad(child_id: string): any {
    const url = `${environment.API_ENDPOINTS}/aolutilities/services?child_id=${child_id}&date=true`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  validateCupos(child_id: any, school_id: string, grade_id: string): any {
    return this.httpClient.get(
      ` https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/validateOmnicanalidad?child_id=${child_id}&school_id=${school_id}&grade_id=${grade_id}`
    );
  }

  validateSignings(child_id: any,school_id: any, grade_id: any){
    return this.httpClient.get(
      ` https://rlpo4vg3p9.execute-api.us-east-1.amazonaws.com/validateOmnicanalidad?child_id=${child_id}&school_id=${school_id}&grade_id=${grade_id}`
    );
  }
}
